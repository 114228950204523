<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <notifications-header :response="response"></notifications-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-notifications-list :response="response">

                            </filterable-paginated-notifications-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
import FetchRootSiren from '../components/FetchSirenRoot';
import FilterablePaginatedNotificationsList from "../components/FilterablePaginatedNotificationsList";
import Loading from '../components/Loading';
import MainTemplate from "@/v2/templates/MainTemplate";
import NotificationsHeader from "../components/NotificationsHeader";
export default {
    components: {
        NotificationsHeader,
        FilterablePaginatedNotificationsList,
        FetchRootSiren,
        Loading,
        MainTemplate
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
